<div class="flex w-full justify-center" data-cy="sick-leave-section">
  <div class="mb-5 flex w-full max-w-7xl flex-col gap-4 bg-lightGrayish p-5 lg:gap-5">
    <div class="flex items-center justify-between">
      <app-text-content-box headline="Sick Leaves" headerIcon="🤒" class="lg:pb-2"></app-text-content-box>
      <div class="flex gap-4">
        <label class="text-sm font-medium text-black">Past sick leaves</label>
        <input
          type="checkbox"
          class="flex h-5 w-9 min-w-[2rem] appearance-none items-center rounded-full bg-gray-300 shadow-inner transition-all duration-300 before:ml-0.5 before:inline-block before:h-4 before:w-4 before:rounded-full before:bg-sunflowerGold checked:bg-yellow-200 checked:before:translate-x-full focus:outline-none"
          data-cy="sick-leave-toggle"
          (change)="togglePastSickLeages()"
        />
      </div>
    </div>

    @if (!sickLeavesAvailable()) {
      <div>
        @if (!showPastSickleaves) {
          <span>There are no current open sick leaves</span>
        } @else {
          <span>There are no past sick leaves</span>
        }
      </div>
    }
    @if (sickLeavesAvailable()) {
      <div class="max-h-80 overflow-y-auto" data-cy="sick-leave-table">
        <div class="sticky top-0 flex w-full text-white">
          <div class="w-60 min-w-[10rem] border-r bg-black p-2">Employee</div>
          <div class="flex min-w-[10rem] flex-1 border-r bg-black p-2">Start Date</div>
          <div class="flex min-w-[10rem] flex-1 border-r bg-black p-2">End Date</div>
          <div class="flex min-w-[10rem] flex-1 border-r bg-black p-2">Days</div>
          <div class="flex min-w-[10rem] flex-1 border-r bg-black p-2">Actions</div>
        </div>
        <div>
          @for (sickLeave of sickLeaves; track sickLeave) {
            @if (sickLeave.isClosed === showPastSickleaves) {
              <div class="flex w-full" data-cy="sick-leave-row">
                <div class="flex w-60 min-w-[10rem] border-b border-l border-r p-2" [title]="sickLeave.employeeName">
                  {{ sickLeave?.employeeName }}
                </div>

                <div class="flex min-w-[10rem] flex-1 border-b border-l border-r p-2" data-cy="sick-leave-start-date">
                  {{ getSickLeaveDate(sickLeave.startDate) }}
                </div>
                <div class="flex min-w-[10rem] flex-1 border-b border-l border-r p-2" data-cy="sick-leave-end-date">
                  {{ getSickLeaveDate(sickLeave.endDate) }}
                </div>
                <div class="flex min-w-[10rem] flex-1 border-b border-l border-r p-2" data-cy="sick-leave-days">
                  {{ getAmountSickDays(sickLeave) }}
                </div>
                <div class="flex w-60 min-w-[10rem] flex-1 justify-center gap-2 border-b border-r p-2">
                  <div
                    class="flex w-8 cursor-pointer items-center justify-center gap-2"
                    data-cy="edit-sickleave-button"
                    (click)="openEditSickLeaveDialog(sickLeave)"
                  >
                    <img class="h-4" src="assets/img/icons/edit.png" alt="edit" />
                  </div>

                  @if (sickLeave.sickNote) {
                    <div
                      (click)="viewFile(sickLeave)"
                      class="flex w-8 cursor-pointer items-center justify-center gap-2"
                      data-cy="upload-show-file-button"
                    >
                      <img class="h-4" src="../../../assets/img/icons/file.png" alt="view" />
                    </div>
                  } @else {
                    <div
                      class="flex w-8 cursor-not-allowed items-center justify-center gap-2 opacity-50"
                      data-cy="upload-show-file-button"
                    >
                      <img class="h-4" src="../../../assets/img/icons/file.png" alt="view" />
                    </div>
                  }

                  <div
                    class="flex w-1/6 cursor-pointer items-center justify-center gap-2"
                    data-cy="edit-closeleave-button"
                    (click)="openCloseConfirmDialog(sickLeave)"
                  >
                    @if (sickLeave.isClosed) {
                      <img class="h-4" src="../../../assets/img/icons/x-mark.png" alt="reopen" />
                    } @else {
                      <img class="h-4" src="../../../assets/img/icons/check.png" alt="close" />
                    }
                  </div>
                  <div
                    class="flex w-8 cursor-pointer items-center justify-center gap-2"
                    data-cy="upload-show-file-button"
                    (click)="openDeleteConfirmDialog(sickLeave)"
                  >
                    <img class="h-4" src="../../../assets/img/icons/delete.png" alt="view" />
                  </div>
                </div>
              </div>
            }
          }
        </div>
      </div>
    }
    <div class="flex flex-wrap justify-end gap-4 md:flex-nowrap">
      <!-- Add Sick Leave Button -->
      <!-- Save Button -->
      <button
        class="flex h-12 min-w-[7rem] items-center justify-center gap-2 border-black bg-almostBlack p-2 font-semibold text-sunflowerGold md:w-auto"
        data-cy="add-sickleave-button"
        (click)="openAddSickLeaveDialog()"
      >
        <p>Add Sick Leave</p>
        <img class="h-[18px] cursor-pointer" src="assets/img/icons/calendar.png" alt="save" />
      </button>
    </div>
  </div>
</div>

@if (showConfirmCloseDialog) {
  <app-confirmation-dialog
    (acceptChanges)="onConfirmCloseLeave($event)"
    (closeDialog)="closeConfirmDialog()"
    [data]="confirmLeaveData"
  ></app-confirmation-dialog>
}
@if (showConfirmDeleteDialog) {
  <app-confirmation-dialog
    (acceptChanges)="onConfirmDeleteLeave($event)"
    (closeDialog)="closeDeleteLeaveDialog()"
    [data]="confirmLeaveData"
  ></app-confirmation-dialog>
}
@if (showEditLeaveDialog) {
  <app-leave-dialog
    (addLeaveData)="setLeaveDialogAction($event, 'add')"
    (editLeaveData)="setLeaveDialogAction($event, 'edit')"
    (closeDialog)="closeLeaveDialog()"
    (deleteLeave)="setLeaveDialogAction($event, 'delete')"
    [employees]="employees"
    [info]="leaveDialog"
    [allLeaves]="sickLeaves"
    [selectedLeave]="leaveData"
    [leaveType]="leaveType"
    [forAnotherEmployee]="true"
  ></app-leave-dialog>
}
