<div
  class="relative m-auto max-h-[90vh] w-full overflow-auto bg-lightGrayish p-12 shadow-lg lg:max-w-[800px]"
  data-cy="personnel-file-container"
>
  <app-text-content-box headline="Profile" [headerIcon]="'📖'"></app-text-content-box>
  <form [formGroup]="employeeForm" (ngSubmit)="onSubmit()">
    <div class="my-6 grid gap-4 lg:grid-cols-2">
      <div>
        <label class="mb-2 block text-sm font-medium">First Name</label>
        <input
          type="text"
          class="block h-12 w-full border border-gray-300 bg-white p-2.5 text-sm"
          data-cy="personnel-file-firstname"
          [(ngModel)]="employee.firstname"
          name="firstname"
        />
        @if (employeeForm.get('firstname').errors?.['required']) {
          <small class="text-errorRed">Firstname is required.</small>
        }
      </div>
      <div>
        <label class="mb-2 block text-sm font-medium">Last Name</label>
        <input
          type="text"
          class="block h-12 w-full border border-gray-300 bg-white p-2.5 text-sm"
          data-cy="personnel-file-lastname"
          required
          [(ngModel)]="employee.lastname"
          name="lastname"
        />
        @if (employeeForm.get('lastname').errors?.['required']) {
          <small class="text-errorRed">Lastname is required.</small>
        }
      </div>
    </div>
    <hr />
    <div class="mb-6 grid gap-4 pt-4 lg:grid-cols-2">
      <div>
        <label class="mb-2 block text-sm font-medium">Address</label>
        <input
          type="text"
          class="block h-12 w-full border border-gray-300 bg-white p-2.5 text-sm"
          data-cy="personnel-file-address"
          [(ngModel)]="employee.address"
          name="address"
        />
        @if (employeeForm.get('address').errors?.['required']) {
          <small class="text-errorRed">Address is required.</small>
        } @else if (employeeForm.get('address').errors?.['pattern']) {
          <small class="text-errorRed">Housnumber is required.</small>
        }
      </div>
      <div>
        <label class="mb-2 block text-sm font-medium">City</label>
        <input
          type="text"
          class="block h-12 w-full border border-gray-300 bg-white p-2.5 text-sm"
          data-cy="personnel-file-city"
          [(ngModel)]="employee.city"
          name="city"
        />
        @if (employeeForm.get('city').errors?.['required']) {
          <small class="text-errorRed">City is required.</small>
        } @else if (employeeForm.get('city').errors?.['pattern']) {
          <small class="text-errorRed">Please enter a valid city.</small>
        }
      </div>
      <div>
        <label class="mb-2 block text-sm font-medium">Postcode</label>
        <input
          type="number"
          class="remove-arrow block h-12 w-full border border-gray-300 bg-white p-2.5 text-sm"
          data-cy="personnel-file-postcode"
          [(ngModel)]="employee.postcode"
          name="postcode"
        />

        @if (employeeForm.get('postcode').errors?.['required']) {
          <small class="text-errorRed">Postcode is required.</small>
        } @else if (employeeForm.get('postcode').errors?.['pattern']) {
          <small class="text-errorRed">Please enter a valid postcode.</small>
        }
      </div>
    </div>
    <hr />
    <div class="mb-6 grid gap-4 pt-4 lg:grid-cols-2">
      <div data-cy="clothing-size-column">
        <label class="mb-2 block text-sm font-medium">Clothing Size</label>
        <app-dropdown
          #clothingSizeDropdown
          (click)="closeAllDropdowns('clothingSizeDropdown')"
          [elements]="clothingSizes"
          placeholder="Choose size"
          [selectedElement]="employee.clothingSize"
          (selectElement)="onSelectClothingSize($event)"
          name="clothingSize"
        ></app-dropdown>
        @if (employeeForm.get('clothingSize').errors?.['required']) {
          <small class="text-errorRed">Clothing size is required.</small>
        }
      </div>
      <div data-cy="shoe-size-column">
        <label class="mb-2 block text-sm font-medium">Shoe Size</label>
        <app-dropdown
          #shoeSizeDropdown
          (click)="closeAllDropdowns('shoeSizeDropdown')"
          [elements]="shoeSizes"
          placeholder="Choose size"
          [selectedElement]="employee.shoeSize"
          (selectElement)="onSelectShoeSize($event)"
          name="shoeSize"
        ></app-dropdown>

        @if (employeeForm.get('shoeSize').errors?.['required']) {
          <small class="text-errorRed">Shoe size is required.</small>
        }
      </div>
    </div>
    <hr />

    <div class="mb-6 grid gap-4 pt-4 lg:grid-cols-2">
      <div data-cy="personnel-birthday-container">
        <label class="mb-2 block text-sm font-medium">Birthday</label>
        <input
          type="text"
          mwlFlatpickr
          class="block h-12 w-full border border-gray-300 bg-white p-2.5 text-sm"
          data-cy="personnel-birthday"
          placeholder="Birthday"
          (change)="onSelectBirthday($event)"
          required
          [altInput]="true"
          [ngModel]="birthday"
          [convertModelValue]="true"
          [options]="helperService.datePickerOptions"
          name="birthday"
        />
      </div>
      <div>
        <label class="mb-2 block text-sm font-medium">IBAN</label>
        <input
          type="text"
          class="remove-arrow block h-12 w-full border border-gray-300 bg-white p-2.5 text-sm"
          data-cy="personnel-file-iban"
          [(ngModel)]="employee.iban"
          name="iban"
        />

        @if (employeeForm.get('iban').errors?.['required']) {
          <small class="text-errorRed">IBAN is required.</small>
        } @else if (employeeForm.get('iban').errors?.['pattern']) {
          <small class="text-errorRed">Please enter a valid IBAN.</small>
        }
      </div>
    </div>
  </form>

  <div class="mt-10">
    <app-text-content-box headline="Password change" [headerIcon]="'🔑'"></app-text-content-box>
    <form class="mt-6" [formGroup]="passwordForm">
      @if (!employee.firstLogin) {
        <div class="grid gap-4 lg:grid-cols-2">
          <div data-cy="clothing-size-column">
            <label class="mb-2 block text-sm font-medium">Old password</label>
            <input
              type="password"
              class="remove-arrow block h-12 w-full border border-gray-300 bg-white p-2.5 text-sm"
              data-cy="personnel-file-old-password"
              formControlName="oldPassword"
            />
          </div>
        </div>
      }

      <div class="mb-6 grid gap-4 pt-4 lg:grid-cols-2">
        <div data-cy="clothing-size-column">
          <label class="mb-2 block text-sm font-medium">Password</label>
          <input
            type="password"
            class="remove-arrow block h-12 w-full border border-gray-300 bg-white p-2.5 text-sm"
            data-cy="personnel-file-new-password"
            formControlName="password"
          />
        </div>
        <div data-cy="shoe-size-column">
          <label class="mb-2 block text-sm font-medium">Confirm Password</label>
          <input
            type="password"
            class="remove-arrow block h-12 w-full border border-gray-300 bg-white p-2.5 text-sm"
            data-cy="personnel-file-confirm-password"
            formControlName="confirmPassword"
          />
        </div>
      </div>
      <div class="flex flex-col gap-2 text-errorRed">
        @if (passwordForm.get('password').errors?.['minlength']) {
          <small>The password must have at least 6 characters.</small>
        } @else if (passwordForm.get('password').errors?.['pattern']) {
          <small>The password must have numbers and text</small>
        }
        @if (passwordForm.errors?.['notMatching']) {
          <small>The confirm password does not match.</small>
        }
      </div>
    </form>
  </div>

  <div class="flex justify-end">
    <button
      type="submit"
      (click)="onSubmit()"
      class="mb-2 flex items-center justify-center border border-black bg-almostBlack px-5 py-2.5 text-center text-sm font-medium tracking-wider text-sunflowerGold shadow-sm hover:bg-black hover:shadow-lg focus:outline-none focus:ring-4 sm:w-auto md:mb-0"
      data-cy="save-personnel-file-dialog"
    >
      Save
    </button>
  </div>
</div>
