import { Component, EventEmitter, Input, Output, ElementRef, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

import { LeaveTimeType } from 'src/app/enum/leave.enum';
import { IEmployee } from 'src/app/models/employee.model';
import { ILeaveDialog } from 'src/app/models/leave-dialog.model';
import { ILeave, Leave } from 'src/app/models/leave.model';

import { AuthService } from 'src/app/services/auth.service';
import { HelperService } from 'src/app/services/helper.service';
import { LeaveService } from 'src/app/services/leave.service';

@Component({
  selector: 'app-leave-dialog',
  templateUrl: './leave-dialog.component.html',
  styleUrls: ['./leave-dialog.component.scss'],
})
export class LeaveDialogComponent {
  @Output() editLeaveData = new EventEmitter<ILeave>();
  @Output() addLeaveData = new EventEmitter<ILeave>();
  @Output() closeDialog = new EventEmitter<boolean>();
  @Output() deleteLeave = new EventEmitter<ILeave>();

  @Input() selectedLeave: ILeave;
  @Input() info: ILeaveDialog;
  @Input() allLeaves: ILeave[];
  @Input() employees: IEmployee[];
  @Input() leaveType: LeaveTimeType = LeaveTimeType.ALL;
  @Input() forAnotherEmployee = false;

  @ViewChild('fileInput') fileInput: any;
  @ViewChild('employeeDropdown') employeeDropdown: any;

  public LeaveTimeType = LeaveTimeType;
  public employeeID: string;
  public minStartDate = new Date();
  public minEndDate: Date;

  public leaveEvents: ILeave[] = [];

  public startDate: string;
  public endDate: string;
  public filename: string = '';

  public selectedLeaveHasSickNote = false;
  public fileUploaded = false;

  constructor(
    public authService: AuthService,
    private toastService: ToastrService,
    public helperService: HelperService,
    private leaveService: LeaveService,
  ) {}

  ngOnInit(): void {
    if (this.leaveType === LeaveTimeType.SICK) {
      this.minStartDate = null;
    }

    if (this.info.type === 'edit') {
      this.selectedLeaveHasSickNote = !!this.selectedLeave?.sickNote;
      this.startDate = this.formattDate(this.selectedLeave.startDate as Date);

      this.endDate = this.formattDate(this.selectedLeave.endDate as Date);
      this.minStartDate = null;

      this.minEndDate = new Date(this.startDate);
    }

    this.allLeaves.forEach((e) => {
      this.leaveEvents.push(e);
    });
  }

  public onSelectStartDate(event: Event) {
    this.startDate = this.helperService.dateToISOString(event);
    this.minEndDate = new Date(this.startDate);
  }

  public onSelectEndDate(event: Event) {
    this.endDate = this.helperService.dateToISOString(event);
  }

  // sets minimum start date on current day for datepicker
  private setMinDate(): void {
    document.getElementById('startDate').setAttribute('min', new Date().toISOString().split('T')[0]);
  }

  public closeEmployeeDropdown(): void {
    if (this.employeeDropdown) this.employeeDropdown.showDropdown = false;
  }

  public onSelectEmployee(employee: IEmployee) {
    this.employeeID = employee._id;
  }

  /**
   * Formattes date to string for NgModel to display it.
   * @param {Date} date - Date that is clicked.
   */
  private formattDate(date: Date): string {
    return date.toISOString().split('T')[0];
  }

  /**
   * Fills leaveOutput object with leave information and type
   */
  public onSubmit(): void {
    if (this.selectedLeave.startDate > this.selectedLeave.endDate) {
      this.toastService.error('Your leave cannot end before it begins!');
    } else {
      if (this.info.type === 'add' && this.forAnotherEmployee && this.employeeID) {
        const employee = this.employees.find((e) => e._id === this.employeeID);
        this.selectedLeave.employeeId = this.employeeID;
        this.selectedLeave.employeeName = employee.firstname + ' ' + employee.lastname;
      }

      this.selectedLeave.startDate = this.startDate as string;
      this.selectedLeave.endDate = this.endDate as string;
      this.selectedLeave.calendarStartDate = this.startDate as string;
      this.selectedLeave.calendarEndDate = this.endDate as string;

      this.checkExistingEvents();
    }
  }

  private addNewLeave(): void {
    this.addLeaveData.emit(this.selectedLeave);
  }

  private editLeave(): void {
    this.editLeaveData.emit(this.selectedLeave);
  }

  /**
   * Checks wheter a leave already exists after adding it
   */
  private checkExistingEvents(): void {
    const newLeave = this.selectedLeave;

    let existingLeaves = this.leaveEvents.find(
      (leave) =>
        (leave.startDate <= newLeave.endDate &&
          leave.endDate >= newLeave.endDate &&
          leave.employeeId === newLeave.employeeId &&
          newLeave._id != leave._id) ||
        (leave.startDate <= newLeave.startDate &&
          leave.endDate >= newLeave.startDate &&
          leave.employeeId === newLeave.employeeId &&
          newLeave._id != leave._id),
    );

    if (existingLeaves) {
      this.toastService.error('There is already an event in this timespan');
    } else if (this.info.type === 'add') {
      this.addNewLeave();
    } else if (this.info.type === 'edit') {
      this.editLeave();
    }
  }

  // toggle for opening employee input
  public anotherEmployee(event: any): void {
    this.forAnotherEmployee = event.checked;
  }

  public onClose(): void {
    this.closeDialog.emit(true);
  }

  public onDelete(): void {
    this.deleteLeave.emit(this.selectedLeave);
  }

  public triggerFileInput(): void {
    this.fileInput.nativeElement.click();
  }

  public onFileSelected(event: any): void {
    const file = event.target.files[0];
    if (file.type !== 'application/pdf') {
      this.toastService.error('Only PDF files are allowed');
      return;
    }

    this.filename = file.name;
    this.handleFile(file);
  }

  private handleFile(file: File): void {
    const reader = new FileReader();
    reader.onload = (e: any) => {
      this.selectedLeave.sickNote = file;
      this.fileUploaded = true;
    };
    reader.readAsDataURL(file);
  }

  public async convertUrlToFile(url: string, fileName: string): Promise<File> {
    return fetch(url)
      .then((response) => response.blob())
      .then((blob) => {
        return new File([blob], fileName, { type: blob.type });
      });
  }

  public downloadSickNote(): void {
    this.leaveService.getLeavesPdf(this.selectedLeave.sickNote as string).subscribe({
      next: (res) => {
        const url = window.URL.createObjectURL(res);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', this.selectedLeave.employeeName + '_sickNote');
        document.body.appendChild(link);
        link.click();
        link.remove();
        window.URL.revokeObjectURL(url);
      },
      error: (err) => {
        this.toastService.error(err.error.MESSAGE);
      },
    });
  }
}
