<div class="min-w-screen fixed inset-0 left-0 top-0 z-50 flex h-screen w-full">
  <div class="absolute inset-0 bg-black opacity-30"></div>
  <div
    [ngClass]="{ 'border-2 border-sunflowerGold': dragEvent }"
    class="relative m-auto w-[28rem] gap-4 bg-lightGrayish p-5 shadow-lg min_w_1640:w-[36rem]"
    data-cy="product-info-card"
  >
    <div
      data-cy="add-product-image"
      (click)="triggerFileInput()"
      (dragover)="onDragOver($event)"
      (dragleave)="onDragLeave($event)"
      (drop)="onDrop($event)"
      class="flex cursor-pointer justify-center"
    >
      <input type="file" #fileInput style="display: none" (change)="onFileSelected($event)" />

      @if (selectedImage) {
        <div class="relative flex h-full min-h-[300px] w-full items-center justify-center">
          <img class="max-h-80 object-cover" [src]="selectedImage" alt="product.img" (load)="imageLoading = true" />

          @if (!imageLoading) {
            <div class="absolute flex h-full w-full items-center justify-center">
              <div
                class="h-10 w-10 animate-spin rounded-full border-2 border-solid border-sunflowerGold border-t-transparent"
              ></div>
            </div>
          }
        </div>
      } @else {
        <div class="flex w-full justify-center">
          <div class="flex h-80 w-full flex-col items-center justify-center bg-white">
            <span class="text-2xl">➕</span>
            <span class="text-sm">Add or Drop Image</span>
          </div>
        </div>
      }
    </div>

    <div class="mt-4 flex flex-col gap-2 font-medium">
      <div class="flex gap-2">
        <input
          type="text"
          class="block h-12 w-full border border-gray-300 p-2.5 placeholder-veryTransparentBlack placeholder:font-semibold"
          data-cy="add-product-title"
          required
          [(ngModel)]="product.title"
          placeholder="Title"
          name="title"
        />
        <div class="relative flex items-center gap-1">
          <input
            type="number"
            class="block h-12 w-full border border-gray-300 p-2.5 placeholder-veryTransparentBlack placeholder:font-semibold"
            data-cy="add-product-price"
            required
            [(ngModel)]="product.price"
            placeholder="Price"
            name="price"
          />

          <img class="absolute right-3 flex h-4" src="../../../assets/img/coin.png" alt="coin" />
        </div>
      </div>

      <textarea
        class="h-20 w-full resize-none border border-gray-300 p-2 placeholder-veryTransparentBlack placeholder:text-sm placeholder:font-semibold focus:outline-none focus:ring-0"
        data-cy="add-product-description"
        placeholder="Description"
        [(ngModel)]="product.description"
      >
      </textarea>
      <app-dropdown
        data-cy="add-product-type"
        [elements]="getProductTypes()"
        placeholder="Select type"
        (selectElement)="onSelectProductType($event)"
        [selectedElement]="product.type"
      ></app-dropdown>
    </div>
    <div class="flex justify-between pt-8">
      <div
        (click)="onCloseDialog()"
        class="mb-2 flex w-32 cursor-pointer items-center justify-center border bg-white px-5 py-2 text-sm font-medium tracking-wider text-slateGray shadow-sm hover:bg-gray-100 hover:shadow-lg md:mb-0"
      >
        Cancel
      </div>
      <div
        (click)="saveProduct()"
        class="flex h-11 w-32 cursor-pointer items-center justify-center border border-black bg-almostBlack p-5 text-sm text-sunflowerGold hover:bg-black"
        data-cy="save-product-btn"
      >
        @if (showLoadingSpinnerInButton) {
          <div class="flex animate-pulse space-x-1">
            <div class="h-1 w-1 rounded-full bg-sunflowerGold"></div>
            <div class="h-1 w-1 rounded-full bg-sunflowerGold"></div>
            <div class="h-1 w-1 rounded-full bg-sunflowerGold"></div>
          </div>
        } @else {
          <span> Save</span>
        }
      </div>
    </div>
  </div>
</div>
